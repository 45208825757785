import React, { useState, useEffect, ComponentClass } from "react";
import { useLocation } from "react-router";
import { H2 } from "app/components/Typography";
import { IS_MOBILE } from "constants/index";
import { useDispatch } from "react-redux";
import {
  RefinementList,
  InstantSearch,
  connectStateResults,
} from "react-instantsearch-dom";
import styled from "styled-components";
import _ from "lodash";
import qs from "qs";
import { closeFiltersAction } from "modules/filters";
import zendesk from "helpers/zendesk";
import { countFilters } from "helpers/filtersUtils";
import Flex from "app/components/Flex";
import ClearButton from "app/components/Algolia/ClearButton";
import Button from "app/components/Button";
import Modal from "app/components/Modal";
import Div from "app/components/Div";
import IconButton from "app/components/Button/IconButton";
import { Close } from "app/components/Icon";
import { searchClient } from "app/components/Algolia/constants";
import { Location } from "history";
import { AccessType } from "services/graphql";
import { AlgoliaIndexes } from "../types";

const urlToSearchState = (location: Location) =>
  qs.parse(location.search.slice(1));

const StyledResultsButton = styled(Button).attrs({
  mt: { _: 2, sm: 0 },
})`
  padding-top: 0;
  padding-bottom: 0;
  height: 32px;

  :disabled {
    opacity: 0.35;
    background: ${({ theme }) => theme.colors.darkGrey};
    color: ${({ theme }) => theme.colors.white};

    @media (hover: hover) {
      :hover {
        background: ${({ theme }) => theme.colors.darkGrey};
      }
    }
  }
`;

const ResultsButton: ComponentClass<any> = connectStateResults(
  ({ onClick, searchResults, totalFiltersApplied, ...rest }: any) => {
    const totalHits =
      totalFiltersApplied === 0 ? "All" : _.get(searchResults, "nbHits");
    if (totalHits === 0) {
      return (
        <StyledResultsButton {...rest} disabled>
          No Results
        </StyledResultsButton>
      );
    }

    return totalHits ? (
      <StyledResultsButton onClick={onClick} {...rest}>
        Show {totalHits} Results
      </StyledResultsButton>
    ) : null;
  }
);

const CloseClearButtons: ComponentClass<any> = connectStateResults(
  ({
    closeFilters,
    refinements,
    totalFiltersApplied,
    searchState,
    clearSortFilter,
  }: any) =>
    totalFiltersApplied > 0 ? (
      <ClearButton
        searchState={searchState}
        clearSortFilter={clearSortFilter}
        transformItems={items =>
          items.filter((item: any) => !refinements[item.attribute])
        }
        position="static"
      />
    ) : (
      <Button py={0} height="32px" variant="secondary" onClick={closeFilters}>
        Close
      </Button>
    )
);

const MobileModal = styled(Modal)`
  width: 100%;
  height: 100%;
  max-height: 100vh;
  max-width: 100vw;
  border-radius: 0;
  overflow: auto;
  padding: 0;
  padding-bottom: 200px; // Accounts for fixed buttons
`;

const DesktopModal = styled(Modal).attrs({
  maxHeight: {
    _: "100%",
    lg: "calc(100vh - 10%)",
  },
  minWidth: { _: "100%", md: "665px" },
})`
  border-radius: 5px;
  overflow: auto;
  padding: 0;
`;

interface Props {
  children: React.ReactNode;
  filterData?: FilterData;
  indexName: AlgoliaIndexes;
  refinements?: { categories: string; accessType?: AccessType[] };
  applyFilters({ newState }: { newState: any }): void;
}

interface FilterData {
  isOpen: boolean;
}

export function FilterModal({
  children,
  filterData = null,
  indexName,
  refinements = { categories: null },
  applyFilters,
}: Props) {
  const location = useLocation();
  const [searchState, setSearchState] = useState(urlToSearchState(location));
  const totalFiltersApplied = countFilters({
    indexName,
    searchState,
    location,
  });
  const dispatch = useDispatch();
  const closeFilters = () => dispatch(closeFiltersAction(indexName));

  useEffect(() => {
    if (filterData?.isOpen) {
      setSearchState(urlToSearchState(location));
      zendesk("webWidget", "hide");
    } else {
      zendesk("webWidget", "show");
    }
  }, [filterData?.isOpen]);

  const clearSortFilter = (callback: () => void) => {
    setSearchState({
      ...searchState,
      sortBy: indexName,
    });
    callback();
  };

  const RenderedModal = IS_MOBILE ? MobileModal : DesktopModal;

  return (
    <InstantSearch
      key={indexName}
      indexName={indexName}
      searchClient={searchClient}
      searchState={searchState}
      onSearchStateChange={setSearchState}
    >
      {/* Need to render RefinementList to reflect correct searchResults count */}
      <div style={{ display: "none" }}>
        <RefinementList
          attribute="categories"
          defaultRefinement={refinements.categories}
        />
        <RefinementList
          attribute="accessType"
          defaultRefinement={refinements.accessType}
        />
      </div>
      <RenderedModal
        isOpen={filterData?.isOpen}
        onBackgroundClick={closeFilters}
      >
        <Flex
          p={3}
          position="sticky"
          top={0}
          bg="white"
          borderBottom="1px solid"
          borderColor="monochrome.1"
          zIndex="1"
          justifyContent="space-between"
          alignItems="center"
        >
          <H2>Filters</H2>
          <IconButton
            width="20px"
            height="20px"
            Icon={Close}
            onClick={closeFilters}
          />
        </Flex>
        <Div p="24px">
          <Div width="100%" height="100%">
            <Div width="100%" height="100%" mb={2}>
              {children}
            </Div>
          </Div>
        </Div>
        <Div
          position={IS_MOBILE ? "fixed" : "sticky"}
          width="100%"
          bottom={0}
          bg="white"
          p={3}
          borderTop="1px solid"
          borderColor="monochrome.1"
        >
          <Flex
            justifyContent="space-between"
            flexDirection={{ _: "column", sm: "row" }}
          >
            <CloseClearButtons
              closeFilters={closeFilters}
              refinements={refinements}
              totalFiltersApplied={totalFiltersApplied}
              clearSortFilter={clearSortFilter}
            />
            <ResultsButton
              totalFiltersApplied={totalFiltersApplied}
              onClick={() => {
                // TODO POTENTIALLY: remove the query empty string if we're combining querying with filtering
                applyFilters({ newState: { ...searchState, query: "" } }); // Clear any search queries from navigation
                closeFilters();
              }}
            />
          </Flex>
        </Div>
      </RenderedModal>
    </InstantSearch>
  );
}
