import ProgramSearch from "app/components/Algolia/ProgramSearch";
import React, { useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { useParams } from "react-router";
import { libraryViewScreen, AccountMode } from "services/typewriter/segment";
import { LIBRARY_MODULE, ANALYTICS_LOCATION } from "constants/index";
import { useFamilyModeSetting } from "hooks/useFamilyModeSetting";
import { useResetInitialPagination } from "hooks/useResetInitialPagination";
import LoaderCentered from "app/components/Loader/LoaderCentered";
import { InProgressPrograms } from "./InProgressPrograms";
import { LibraryContainer } from "../LibraryContainer";

export const Programs = () => {
  const { categorySlug } = useParams();
  const { isFamilyModeOn } = useFamilyModeSetting();
  const { resettingPagination } = useResetInitialPagination();

  useEffect(() => {
    libraryViewScreen({
      screen_name: "Library - Programs",
      location: ANALYTICS_LOCATION.library,
      module: LIBRARY_MODULE.library_programs,
      account_mode: isFamilyModeOn ? AccountMode.Family : AccountMode.Studio,
    });
  }, []);

  if (resettingPagination) {
    return <LoaderCentered />;
  }

  return (
    <>
      <Helmet title="Programs | Online Dance Classes and Tutorials" />
      <InProgressPrograms />
      <LibraryContainer>
        <ProgramSearch
          refinements={{
            categories: categorySlug,
          }}
        />
      </LibraryContainer>
    </>
  );
};
