import styled from "styled-components";
import Flex from "app/components/Flex";
import { H7 } from "app/components/Typography";

export const OptionWrapper = styled(Flex)`
  cursor: pointer;
  border: 1.5px solid;
  border-radius: 2px;

  ${({ active, theme }) =>
    active
      ? `
    background: ${theme.colors.monochrome[8]};
    color: ${theme.colors.white};
    border-color: ${theme.colors.monochrome[8]};
  `
      : `
    color: ${theme.colors.black};
    border-color: ${theme.colors.monochrome[1]};
    @media (hover: hover) {
      :hover {
        background: ${theme.colors.monochrome[1]};
      }
    }
  `}}

  ${({ disabled, theme }) =>
    disabled &&
    `
      cursor: not-allowed;
      opacity: 0.35;
      background: ${theme.colors.darkGrey};
      color: ${theme.colors.white};
    `}
`;

export const OptionName = styled(H7)`
  font-size: 12px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
`;
