import ProgramCardList from "app/components/ProgramCardList";
import ProgramCard from "app/components/ProgramCard";
import PropTypes from "prop-types";
import React from "react";

const ProgramList = ({ programs, selectedFrom, sentinel, ...rest }) => (
  <ProgramCardList
    {...rest}
    cards={programs}
    sentinel={sentinel}
    renderCard={({ cardData, cardListWidth }) => (
      <ProgramCard
        key={cardData.slug}
        data={cardData}
        selectedFrom={selectedFrom}
        cardListWidth={cardListWidth}
      />
    )}
  />
);
ProgramList.defaultProps = {
  parentRef: null,
  selectedFrom: "ProgramList",
  sentinel: null,
};

ProgramList.propTypes = {
  parentRef: PropTypes.shape({}),
  programs: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  selectedFrom: PropTypes.string,
  sentinel: PropTypes.element,
};

export default ProgramList;
