import React from "react";
import PropTypes from "prop-types";
import { connectCurrentRefinements } from "react-instantsearch-dom";
import Button from "app/components/Button";

function ClearButton({ refine, items, searchState, clearSortFilter, ...rest }) {
  const hasSortFilter = searchState && !!searchState.sortBy;
  if (!items.length && !hasSortFilter) {
    return null;
  }

  return (
    <Button
      variant="secondary"
      onClick={() => {
        clearSortFilter(() => refine(items));
      }}
      {...rest}
    >
      Clear All
    </Button>
  );
}

ClearButton.propTypes = {
  items: PropTypes.instanceOf(Array).isRequired,
  refine: PropTypes.func.isRequired,
  clearSortFilter: PropTypes.func.isRequired,
  searchState: PropTypes.shape({}).isRequired,
};

export default connectCurrentRefinements(ClearButton);
