import qs from "qs";
import _ from "lodash";
import { AlgoliaIndexes } from "app/components/Algolia/types";

export const getFilterEventObj = ({ location, indexName, searchState }) => {
  let eventObj = {
    levels: _.get(searchState, "refinementList.level"),
    sort: _.get(searchState, "sortBy"),
    path: location.pathname,
  };

  if (indexName === AlgoliaIndexes.classes) {
    eventObj = {
      ...eventObj,
      types: _.get(searchState, "refinementList.type"),
      class_duration_in_seconds: _.get(
        searchState,
        "multiRange.duration_in_seconds"
      ),
      instructors: _.get(searchState, "refinementList.instructor_name"),
      categories: [_.get(searchState, "refinementList.categories")],
    };
  } else if (indexName === AlgoliaIndexes.programs) {
    eventObj = {
      ...eventObj,
      styles: _.get(searchState, "refinementList.style"),
      categories: [_.get(searchState, "refinementList.categories")],
    };
  }

  return eventObj;
};

export const countFilters = ({ searchState: state, indexName, location }) => {
  const searchState = state || qs.parse(location.search.slice(1));
  const sortBy = _.get(searchState, "sortBy");

  const sort = sortBy === indexName ? "" : sortBy;

  const levels = _.get(searchState, "refinementList.level", []);
  let totalFilters = [sort, ...levels];

  if (indexName === AlgoliaIndexes.classes) {
    const durationRange = _.get(
      searchState,
      "multiRange.duration_in_seconds",
      ""
    );
    const instructors = _.get(
      searchState,
      "refinementList.instructor_name",
      []
    );
    const types = _.get(searchState, "refinementList.type", []);
    const styles = _.get(searchState, "refinementList.style", []);
    totalFilters = [
      durationRange,
      ...totalFilters,
      ...instructors,
      ...styles,
      ...types,
    ];
  } else if (indexName === AlgoliaIndexes.programs) {
    const styles = _.get(searchState, "refinementList.style", []);
    totalFilters = [...totalFilters, ...styles];
  }

  return totalFilters.filter(filter => Boolean(filter)).length;
};
