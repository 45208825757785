import React from "react";
import PropTypes from "prop-types";
import { connectSortBy } from "react-instantsearch-dom";
import FilterOptions from "app/components/Algolia/FilterOptions";
import Div from "app/components/Div";

const SortByRefinment = connectSortBy(
  ({ items, defaultRefinement, refine }) => {
    const options = items.map(item => ({
      name: item.label,
      handleOnClick: () =>
        refine(item.isRefined ? defaultRefinement : item.value),
      active: item.isRefined,
    }));

    return <FilterOptions label="Sort By" options={options} />;
  }
);

export function SortOptions({ defaultRefinement, items, ...props }) {
  return (
    <Div {...props}>
      <SortByRefinment defaultRefinement={defaultRefinement} items={items} />
    </Div>
  );
}

SortOptions.propTypes = {
  defaultRefinement: PropTypes.string.isRequired,
  items: PropTypes.instanceOf(Array).isRequired,
};
