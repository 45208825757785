import React from "react";
import styled from "styled-components";
import { space, typography } from "styled-system";
import { withRouter } from "react-router";
import PropTypes from "prop-types";
import { countFilters } from "helpers/filtersUtils";

const ButtonWrapper = styled.div`
  position: fixed;
  left: 0;
  bottom: 100px;
  z-index: 999;
  margin-top: 20px;
  width: 100%;
  display: flex;
  justify-content: center;

  ${({ theme }) => theme.mediaQueries.gtmd} {
    display: none;
  }
`;

const StyledButton = styled.button`
  text-transform: uppercase;
  letter-spacing: 2px;
  border: 1px solid ${({ theme }) => theme.colors.lightGrey};
  border-radius: 2px;
  background: ${({ theme }) => theme.colors.white};
  color: ${({ theme }) => theme.colors.black};
  cursor: pointer;

  @media (hover: hover) {
    :hover {
      background: ${({ theme }) => theme.colors.lightGrey};
    }
  }

  ${typography}
  ${space}
`;

const MobileFilterButton = ({ indexName, openFilter, location }) => {
  const totalFiltersApplied = countFilters({ indexName, location });
  return (
    <ButtonWrapper>
      <StyledButton onClick={openFilter} fontSize={[3]} py={[3]} px={[5]}>
        Filter{totalFiltersApplied ? ` (${totalFiltersApplied})` : null}
      </StyledButton>
    </ButtonWrapper>
  );
};

MobileFilterButton.propTypes = {
  indexName: PropTypes.string.isRequired,
  openFilter: PropTypes.func.isRequired,
  location: PropTypes.shape({}).isRequired,
};

export default withRouter(MobileFilterButton);
