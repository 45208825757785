export function normalizeFacets({ facets, items }) {
  return facets.map(facet => {
    const found = items.find(i => i.label === facet);
    if (found) {
      return found;
    }
    return {
      label: facet,
      value: [facet],
      isRefined: false,
      disabled: true,
    };
  });
}
