import React from "react";
import PropTypes from "prop-types";
import { connectRefinementList } from "react-instantsearch-dom";
import FilterOptions from "app/components/Algolia/FilterOptions";
import Div from "app/components/Div";
import { normalizeFacets } from "./normalizeFacets";
import { normalizeOptions } from "./normalizeOptions";

// Level order is used to sort facets
const LEVEL_ORDER = {
  Beginner: 1,
  Intermediate: 2,
  Advanced: 3,
};

const RefinementList = connectRefinementList(
  ({ initialFacets, refine, items }) => {
    const facets = normalizeFacets({ facets: initialFacets, items });
    const sortedFacets = facets.sort(
      (a, b) => LEVEL_ORDER[a.label] - LEVEL_ORDER[b.label]
    );
    const options = normalizeOptions({ options: sortedFacets, refine });
    const activeCount = options.filter(option => option.active).length;

    return (
      <FilterOptions
        label={activeCount > 0 ? `Level (${activeCount})` : "Level"}
        options={options}
        gridTemplateColumns="repeat(3, 1fr)"
      />
    );
  }
);

export function LevelOptions({ forceDefault = false, initialFacets, ...rest }) {
  if (forceDefault || !initialFacets) {
    return null;
  }

  return (
    <Div {...rest}>
      <RefinementList attribute="level" initialFacets={initialFacets} />
    </Div>
  );
}

LevelOptions.propTypes = {
  forceDefault: PropTypes.bool,
  initialFacets: PropTypes.instanceOf(Array).isRequired,
};
