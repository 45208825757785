import React from "react";
import { withRouter } from "react-router";
import PropTypes from "prop-types";
import {
  Configure,
  InstantSearch,
  RefinementList,
  SortBy,
} from "react-instantsearch-dom";
import qs from "qs";
import { connect } from "react-redux";
import { openFiltersAction } from "modules/filters";
import InfiniteContentList from "app/components/InfiniteContentList";
import ProgramList from "app/components/ProgramList";
import AlgoliaLoader from "app/components/Loader/AlgoliaLoader";
import { ProgramsInactive } from "app/components/Icon";
import { searchClient } from "app/components/Algolia/constants";
import { AlgoliaIndexes } from "../types";

const createURL = state => `?${qs.stringify(state)}`;
class ProgramResults extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      searchState: {},
    };
  }

  static getDerivedStateFromProps({ searchStateProp }) {
    return {
      searchState: searchStateProp,
    };
  }

  onSearchStateChange = searchState => {
    const { setSearchState } = this.props;
    setSearchState({ newState: searchState });
  };

  render() {
    const { searchState } = this.state;
    const {
      hitsPerPage,
      refinements,
      openFilter,
      filters,
      ...restOfPropsForProgramList
    } = this.props;

    return (
      <InstantSearch
        indexName={AlgoliaIndexes.programsByPublishDateAsc}
        searchClient={searchClient}
        searchState={searchState}
        onSearchStateChange={this.onSearchStateChange}
        createURL={createURL}
      >
        <div style={{ display: "none" }}>
          <RefinementList
            attribute="style"
            forceDefault={!!refinements.style}
            defaultRefinement={refinements.style || []}
          />
          <RefinementList attribute="level" />
          <RefinementList
            attribute="categories"
            defaultRefinement={refinements.categories || []}
          />
          <SortBy
            defaultRefinement={AlgoliaIndexes.programs}
            items={[
              {
                value: AlgoliaIndexes.programsByPublishDateDesc,
                label: "Newest",
              },
              {
                value: AlgoliaIndexes.programsByPublishDateAsc,
                label: "Oldest",
              },
            ]}
          />
        </div>
        <Configure hitsPerPage={hitsPerPage} query={searchState.query} />
        <InfiniteContentList
          indexName={AlgoliaIndexes.programs}
          openFilter={openFilter}
          filters={filters}
          renderList={({ hits, sentinel }) =>
            hits.length > 0 ? (
              <ProgramList
                programs={hits}
                selectedFrom="ProgramResults"
                sentinel={sentinel}
                {...restOfPropsForProgramList}
              />
            ) : (
              <AlgoliaLoader icon={ProgramsInactive} listType="programs" />
            )
          }
          {...restOfPropsForProgramList}
        />
      </InstantSearch>
    );
  }
}

const mapStateToProps = ({ filters }) => ({
  filters: filters.programsV2,
});

const mapDispatchToProps = {
  openFilter: () => openFiltersAction("programsV2"),
};

ProgramResults.defaultProps = {
  hitsPerPage: 15,
  refinements: {},
};

ProgramResults.propTypes = {
  history: PropTypes.shape({}).isRequired,
  hitsPerPage: PropTypes.number,
  location: PropTypes.shape({}).isRequired,
  refinements: PropTypes.shape({}),
  searchStateProp: PropTypes.shape({}).isRequired,
  setSearchState: PropTypes.func.isRequired,
  openFilter: PropTypes.func.isRequired,
  filters: PropTypes.shape({}).isRequired,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ProgramResults)
);
