import React from "react";
import PropTypes from "prop-types";
import styled, { withTheme } from "styled-components";
import Flex from "app/components/Flex";

const Wrapper = styled(Flex)`
  flex-wrap: wrap;
  gap: 24px;
  width: 100%;
`;

const ProgramCardList = ({ cards, renderCard, sentinel, ...props }) => {
  return (
    <Wrapper {...props}>
      {cards &&
        cards.map((cardData, index) =>
          cardData.placeholder ? (
            <div />
          ) : (
            renderCard({
              cardData,
              index,
            })
          )
        )}
      {sentinel}
    </Wrapper>
  );
};

ProgramCardList.defaultProps = {
  sentinel: null,
};

ProgramCardList.propTypes = {
  cards: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  renderCard: PropTypes.func.isRequired,
  sentinel: PropTypes.element,
};

export default withTheme(ProgramCardList);
