import React from "react";
import PropTypes from "prop-types";
import { H3 } from "app/components/Typography";
import Div from "app/components/Div";
import Grid from "app/components/Grid";
import { OptionWrapper, OptionName } from "./styles";

const FilterOptions = ({ label, options, gridTemplateColumns }) => {
  return (
    <Div>
      <H3>{label}</H3>
      <Grid gridTemplateColumns={gridTemplateColumns} gridGap={2} mt={3}>
        {options &&
          options.map(option => {
            return (
              <OptionWrapper
                p={2}
                alignItems="center"
                height="32px"
                active={option.active}
                onClick={option.handleOnClick}
                disabled={option.disabled}
              >
                <OptionName>{option.name}</OptionName>
              </OptionWrapper>
            );
          })}
      </Grid>
    </Div>
  );
};

FilterOptions.defaultProps = {
  gridTemplateColumns: "repeat(1, 1fr)",
};

FilterOptions.propTypes = {
  gridTemplateColumns: PropTypes.string,
  label: PropTypes.string.isRequired,
  options: PropTypes.instanceOf(Array).isRequired,
};

export default FilterOptions;
