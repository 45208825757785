import React from "react";
import PropTypes from "prop-types";
import { connectRefinementList } from "react-instantsearch-dom";
import FilterOptions from "app/components/Algolia/FilterOptions";
import Div from "app/components/Div";
import { normalizeFacets } from "./normalizeFacets";
import { normalizeOptions } from "./normalizeOptions";

const RefinementList = connectRefinementList(
  ({ initialFacets, refine, items }) => {
    const facets = normalizeFacets({ facets: initialFacets, items });
    const options = normalizeOptions({ options: facets, refine });
    const activeCount = options.filter(option => option.active).length;

    return (
      <FilterOptions
        label={activeCount > 0 ? `Styles (${activeCount})` : "Styles"}
        options={options}
        gridTemplateColumns="repeat(2, 1fr)"
      />
    );
  }
);

export function StyleOptions({
  forceDefault = false,
  initialFacets = null,
  ...rest
}) {
  if (forceDefault || !initialFacets) {
    return null;
  }

  return (
    <Div {...rest}>
      <RefinementList
        initialFacets={initialFacets}
        attribute="style"
        limit={9999}
      />
    </Div>
  );
}

StyleOptions.propTypes = {
  forceDefault: PropTypes.bool,
  initialFacets: PropTypes.instanceOf(Array),
};
