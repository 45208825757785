import React from "react";
import { useGetProgramsHistoryQuery } from "services/graphql";
import { Slider } from "app/components/Slider";
import ProgramCard from "app/components/ProgramCard";
import Flex from "app/components/Flex";
import { H2 } from "app/components/Typography";

export function InProgressPrograms() {
  const { data, loading, error } = useGetProgramsHistoryQuery({
    variables: {
      // We do not have more than 50 programs as of 10/17/2023
      first: 50,
    },
  });

  if (loading) {
    return null;
  }

  if (error) {
    return null;
  }

  if (data?.programsHistory?.connection?.edges?.length <= 0) {
    return null;
  }

  const normalizedProgramData = data.programsHistory.connection.edges.map(
    ({ node }) => node
  );

  return (
    <Flex bg="black" justifyContent="center" mb={4}>
      <Flex width="100%" maxWidth="1600px" flexDirection="column" p={[3, 3, 4]}>
        <H2 color="white" mb="16px">
          In Progress
        </H2>
        <Slider
          data={normalizedProgramData}
          slideComponent={ProgramCard}
          selectedFrom="DancePrograms"
          arrowTopPosition="35%"
          slidesSpacing={32}
          slidesWidth={280}
        />
      </Flex>
    </Flex>
  );
}
