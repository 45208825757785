import React from "react";
import { withRouter } from "react-router";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import qs from "qs";
import _ from "lodash";
import { RefinementList } from "react-instantsearch-dom";
import { getFilterEventObj } from "helpers/filtersUtils";
import { LIBRARY_MODULE, ANALYTICS_LOCATION } from "constants/index";
import {
  contentFilterPrograms,
  libraryFilterContent,
  AccountMode,
} from "services/typewriter/segment";
import { FamilyContext } from "app/components/AppProviders/FamilyContextProvider";
import { getFiltersApplied } from "helpers/getFiltersApplied";
import { searchClient } from "app/components/Algolia/constants";
import { FilterModal } from "../FilterModal";
import { SortOptions } from "../SortOptions";
import { LevelOptions } from "../LevelOptions";
import { StyleOptions } from "../StyleOptions";
import ProgramResults from "./ProgramResults";
import { AlgoliaIndexes } from "../types";

const createURL = state => {
  const { ...withoutPage } = state;
  return `?${qs.stringify(withoutPage)}`;
};
const urlToSearchState = location => qs.parse(location.search.slice(1));
const searchStateToUrl = (props, searchState) =>
  searchState ? `${props.location.pathname}${createURL(searchState)}` : "";

class ProgramSearch extends React.Component {
  static contextType = FamilyContext;

  constructor(props) {
    super(props);
    this.state = {
      searchState: urlToSearchState(props.location),
    };
  }

  async componentDidMount() {
    const index = searchClient.initIndex(AlgoliaIndexes.programs);
    const { facets } = await index.search("", { facets: ["style", "level"] });

    this.setState({
      initialFacets: _.reduce(
        facets,
        (obj, value, key) => ({ ...obj, [key]: Object.keys(value) }),
        {}
      ),
    });
  }

  componentDidUpdate(prevProps, prevState) {
    const { searchState } = this.state;
    const { location, history, refinements } = this.props;

    if (!_.isEqual(searchState, prevState.searchState)) {
      history.push(searchStateToUrl(this.props, searchState), searchState);
      const filterObj = getFilterEventObj({
        location,
        indexName: AlgoliaIndexes.programs,
        searchState: {
          ...searchState,
          refinementList: { ...searchState.refinementList, ...refinements },
        },
      });
      contentFilterPrograms(filterObj);
    }

    // Reset filters when category changed
    if (!_.isEqual(refinements, prevProps.refinements)) {
      this.setState({
        searchState: { ...searchState, refinementList: {} },
      });
    }
  }

  setSearchState = ({ newState }) => {
    this.setState({ searchState: newState });
  };

  applyFilters = ({ newState }) => {
    const { history, location } = this.props;
    const { isFamilyModeOn } = this.context;
    const prevPath = location.state?.prevPath;
    const { refinementList } = newState;
    const accountMode = isFamilyModeOn
      ? AccountMode.Family
      : AccountMode.Studio;

    if (location.pathname?.includes("/results")) {
      this.setState({ searchState: newState });

      if (prevPath === "/library/categories") {
        libraryFilterContent({
          location: ANALYTICS_LOCATION.library,
          module: LIBRARY_MODULE.library_categories,
          filters_applied: getFiltersApplied({ refinementList }),
          account_mode: accountMode,
        });
      }
    } else {
      libraryFilterContent({
        location: ANALYTICS_LOCATION.library,
        module: LIBRARY_MODULE.library_programs,
        filters_applied: getFiltersApplied({ refinementList }),
        account_mode: accountMode,
      });

      history.push(`/results/programs${createURL(newState)}`, {
        prevPath: location.pathname,
      });
    }
  };

  render() {
    const { initialFacets, searchState } = this.state;
    const {
      refinements,
      programFilters,
      location,
      ...restOfPropsForProgramList
    } = this.props;

    const searchStateFromURL = urlToSearchState(location);
    if (!_.isEqual(searchStateFromURL.query, searchState.query)) {
      this.setState({ searchState: searchStateFromURL });
    }

    return (
      <React.Fragment>
        <FilterModal
          indexName={AlgoliaIndexes.programs}
          indexTitle={AlgoliaIndexes.programs}
          filterData={programFilters}
          refinements={refinements}
          location={location}
          applyFilters={this.applyFilters}
        >
          <div style={{ display: "none" }}>
            <RefinementList
              attribute="categories"
              defaultRefinement={refinements.categories || []}
            />
          </div>
          <LevelOptions mb={4} initialFacets={initialFacets?.level} />
          <StyleOptions mb={4} initialFacets={initialFacets?.style} />
          <SortOptions
            mb={4}
            defaultRefinement={AlgoliaIndexes.programs}
            items={[
              {
                value: AlgoliaIndexes.programsByPublishDateDesc,
                label: "Newest",
              },
              {
                value: AlgoliaIndexes.programsByPublishDateAsc,
                label: "Oldest",
              },
            ]}
          />
        </FilterModal>

        <ProgramResults
          refinements={refinements}
          searchStateProp={searchState}
          setSearchState={this.setSearchState}
          {...restOfPropsForProgramList}
        />
      </React.Fragment>
    );
  }
}

const mapStateToProps = ({ filters }) => ({
  programFilters: filters.programsV2,
});

ProgramSearch.defaultProps = {
  refinements: {},
};

ProgramSearch.propTypes = {
  history: PropTypes.shape({}).isRequired,
  location: PropTypes.shape({}).isRequired,
  programFilters: PropTypes.shape({}).isRequired,
  refinements: PropTypes.shape({}),
};

export default withRouter(connect(mapStateToProps, null)(ProgramSearch));
